import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        userInfo: "",
        teacherId: "3",
        guardianId: "2",
        openid: "",
        // 该监护人下的宝贝信息
        // children: [{studentName:"加载中...",studentId:"加载中..."}],
        children: [{ studentName: "", studentId: "" }],
        // child: {text:"加载中...",studentId:"加载中..."},
        child: { text: "", studentId: "" },
        // 该老师下的班级信息
        // classes: [{gradeClazzName:"加载中...",gradeClazzId:"加载中..."}],
        classes: [{ gradeClazzName: "", gradeClazzId: "" }],
        // clazz: {text:"加载中...",clazzId:"加载中..."},
        clazz: { text: "", clazzId: "" },
        // 头像
        headImgUrl: "",
        // teacherName: "加载中...",
        // guardianName: "加载中...",
        teacherName: "",
        guardianName: "",
        // 用户类型 1为老师  2为家长
        type: 0,
        // 未读消息数量 用于tabbar角标
        unreadCount: 0,
        // keepAlive用的数组
        // keepAlive:["homepage","tabMessage","tabMe","leaveApplicationRecord"],
        schoolInfo: "",
        childrenV2: "",
    },
    mutations: {
        setSchoolInfo(state, newSchoolInfo) {
            state.schoolInfo = newSchoolInfo;
        },
        setUserInfo(state, newUserInfo) {
            state.userInfo = newUserInfo;
        },
        setOpenid(state, newOpenid) {
            state.openid = newOpenid;
        },
        setTeacherId(state, newTeachId) {
            state.teacherId = newTeachId;
        },
        setGuardianId(state, newGuardianId) {
            state.guardianId = newGuardianId;
        },
        setChildren(state, newChildren) {
            state.children = newChildren;
        },
        setChild(state, newChild) {
            state.child = newChild;
        },
        setClasses(state, newClasses) {
            state.classes = newClasses;
        },
        setClazz(state, newClazz) {
            state.clazz = newClazz;
        },
        setType(state, newType) {
            state.type = newType;
        },
        setHeadImgUrl(state, newHeadImgUrl) {
            state.headImgUrl = newHeadImgUrl;
        },
        setTeacherName(state, newTeacherName) {
            state.teacherName = newTeacherName;
        },
        setGuardianName(state, newGuardianName) {
            state.guardianName = newGuardianName;
        },
        setUnreadCount(state, newUnreadCount) {
            state.unreadCount = newUnreadCount;
        },
        decreaseUnreadCount(state) {
            state.unreadCount--;
        },
        setChildrenV2(state, newChildrenV2) {
            state.childrenV2 = newChildrenV2;
        },
    },
    getters: {
        // openid: state => state.openid,
        // userInfo: state => state.userInfo,
        // teacherId: state => state.teacherId,
        // guardianId: state => state.guardianId,
        // teacherName: state => state.teacherName,
        // guardianName: state => state.guardianName,
        // headImgUrl: state => state.headImgUrl,
        getChildren(state) {
            var res = new Array();
            state.children.map((item) => {
                res.push({ text: item.studentName, studentId: item.studentId });
            });
            return res;
        },
        // child: state => state.child,
        getClasses(state) {
            var res = new Array();
            state.classes.map((item) => {
                res.push({ text: item.gradeClazzName, clazzId: item.gradeClazzId });
            });
            return res;
        },
    },
    actions: {},
    modules: {},
});
