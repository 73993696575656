<template>
  <div id="app">
    <!-- <router-view style="padding-bottom:50px" /> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" style="padding-bottom: 50px" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" style="padding-bottom: 50px" />

    <!-- <keep-alive :include="keepAlive">
      <router-view style="padding-bottom:50px" />
    </keep-alive> -->
    <van-tabbar
      style="height: 50px"
      v-model="active"
      v-show="$route.meta.showTabbar"
    >
      <van-tabbar-item replace to="/" icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item
        class="badge_item"
        replace
        to="/tabMessage"
        :dot="unreadCount != 0"
        icon="comment-o"
        >消息</van-tabbar-item
      >
      <!-- <van-tabbar-item class="badge_item" replace to="/tabMessage" :badge="unreadCount" icon="comment-o" >消息</van-tabbar-item> -->
      <!-- <van-tabbar-item
        replace
        to="/tabAnnouncement"
        icon="newspaper-o"
        badge=""
      >
        公告
      </van-tabbar-item> -->
      <van-tabbar-item replace to="/tabMe" icon="user-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      // active: "homepage",
    };
  },
  watch: {},

  computed: {
    ...mapState({
      unreadCount: "unreadCount",
    }),
    active: {
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, vue/return-in-computed-property
      get() {
        switch (this.$route.path.split("/")[1]) {
          case "":
            return 0;
          case "tabMessage":
            return 1;
          case "tabAnnouncement":
            return 2;
          case "tabMe":
            return 3;
          default:
            break;
        }
      },
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-empty-function
      set() {},
    },
    // keepAlive () {
    //   return this.$store.getters.keepAlive
    // }
  },
};
</script>

<style lang="scss">
#app {
  height: 100%;
}
</style>
