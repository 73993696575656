import Vue from "vue";
import VueRouter from "vue-router";
const login = () => import(/* webpackChunkName: "about" */ "../views/login.vue");
const homepage = () => import(/* webpackChunkName: "about" */ "../views/homepage.vue");
const tabMessage = () => import(/* webpackChunkName: "about" */ "../views/tabMessage.vue");
const messageDetail = () => import(/* webpackChunkName: "about" */ "../views/messageDetail.vue");
const tabAnnouncement = () => import(/* webpackChunkName: "about" */ "../views/tabAnnouncement.vue");
const announcementDetail = () => import(/* webpackChunkName: "about" */ "../views/announcementDetail.vue");
const tabMe = () => import(/* webpackChunkName: "about" */ "../views/tabMe.vue");
const studentList = () => import(/* webpackChunkName: "about" */ "../views/studentList.vue");
const connect = () => import(/* webpackChunkName: "about" */ "../views/connect.vue");
const connectDetail = () => import(/* webpackChunkName: "about" */ "../views/connectDetail.vue");
const changePasswordOrPhone = () => import(/* webpackChunkName: "about" */ "../views/changePasswordOrPhone.vue");
const leaveApplication = () => import(/* webpackChunkName: "about" */ "../views/leaveApplication.vue");
const leaveApplicationRecord = () => import(/* webpackChunkName: "about" */ "../views/leaveApplicationRecord.vue");
const leaveApplicationDetail = () => import(/* webpackChunkName: "about" */ "../views/leaveApplicationDetail.vue");
const schoolIntroduction = () => import(/* webpackChunkName: "about" */ "../views/schoolIntroduction.vue");
const attendanceSummary = () => import(/* webpackChunkName: "about" */ "../views/attendanceSummary.vue");
const pickupSummary = () => import(/* webpackChunkName: "about" */ "../views/pickupSummary.vue");
const register = () => import(/* webpackChunkName: "about" */ "../views/register.vue");
const guardianRegister = () => import(/* webpackChunkName: "about" */ "../views/guardianRegister.vue");
const paymentRecord = () => import(/* webpackChunkName: "about" */ "../views/paymentRecord.vue");
const paymentManagement = () => import(/* webpackChunkName: "about" */ "../views/paymentManagement.vue");
Vue.use(VueRouter);
const routes = [
    {
        path: "/register",
        name: "register",
        component: register,
        meta: {
            showTabbar: false,
            keepAlive: false,
        },
    },
    {
        path: "/guardianRegister",
        name: "guardianRegister",
        component: guardianRegister,
        meta: {
            showTabbar: false,
            keepAlive: false,
        },
    },
    {
        path: "/login",
        name: "login",
        meta: {
            showTabbar: false,
            keepAlive: false,
        },
        component: login,
    },
    {
        // path: "/:messageType/:recordId",
        path: "/",
        name: "homepage",
        meta: {
            showTabbar: true,
            keepAlive: true,
        },
        component: homepage,
    },
    {
        path: "/tabMessage",
        name: "tabMessage",
        meta: {
            showTabbar: true,
            keepAlive: true,
        },
        component: tabMessage,
    },
    {
        path: "/tabMessage/messageDetail/:messageType",
        name: "messageDetail",
        meta: {
            showTabbar: false,
            keepAlive: false,
        },
        component: messageDetail,
    },
    {
        path: "/paymentManagement",
        name: "paymentManagement",
        meta: {
            showTabbar: true,
            keepAlive: true,
        },
        component: paymentManagement,
    },
    {
        path: "/paymentRecord",
        name: "paymentRecord",
        meta: {
            showTabbar: true,
            keepAlive: true,
        },
        component: paymentRecord,
    },
    {
        path: "/tabAnnouncement",
        name: "tabAnnouncement",
        meta: {
            showTabbar: true,
            keepAlive: false,
        },
        component: tabAnnouncement,
    },
    {
        path: "/tabAnnouncement/announcementDetail",
        name: "announcementDeatail",
        meta: {
            showTabbar: false,
            keepAlive: false,
        },
        component: announcementDetail,
    },
    {
        path: "/tabMe",
        name: "tabMe",
        meta: {
            showTabbar: true,
            keepAlive: true,
            reload: false,
        },
        component: tabMe,
    },
    {
        path: "/tabMe/studentList",
        name: "studentList",
        meta: {
            showTabbar: false,
            keepAlive: true,
            reload: false,
        },
        component: studentList,
    },
    {
        path: "/tabMe/connect/:type",
        name: "connect",
        meta: {
            showTabbar: false,
            keepAlive: false,
        },
        component: connect,
    },
    {
        path: "/tabMe/connect/:type/connectDetail",
        name: "connectDetail",
        meta: {
            showTabbar: false,
            keepAlive: false,
        },
        component: connectDetail,
    },
    {
        path: "/tabMe/changePasswordOrPhone",
        name: "changePasswordOrPhone",
        meta: {
            showTabbar: false,
            keepAlive: false,
        },
        component: changePasswordOrPhone,
    },
    {
        path: "/leaveApplication",
        name: "leaveApplication",
        meta: {
            showTabbar: false,
            keepAlive: false,
        },
        component: leaveApplication,
    },
    {
        path: "/leaveApplicationRecord",
        name: "leaveApplicationRecord",
        meta: {
            showTabbar: false,
            keepAlive: true,
            update: "",
        },
        component: leaveApplicationRecord,
    },
    {
        path: "/leaveApplicationRecord/leaveApplicationDetail",
        name: "leaveApplicationDetail",
        meta: {
            showTabbar: false,
            keepAlive: false,
            update: false,
        },
        component: leaveApplicationDetail,
    },
    {
        path: "/schoolIntroduction",
        name: "schoolIntroduction",
        meta: {
            showTabbar: false,
            keepAlive: false,
        },
        component: schoolIntroduction,
    },
    {
        path: "/attendanceSummary",
        name: "attendanceSummary",
        meta: {
            showTabbar: false,
            keepAlive: true,
            reload: true,
        },
        component: attendanceSummary,
    },
    {
        path: "/pickupSummary",
        name: "pickupSummary",
        meta: {
            showTabbar: false,
            keepAlive: true,
            reload: true,
        },
        component: pickupSummary,
    },
];
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});
export default router;
